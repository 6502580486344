if (window.location.origin.includes("localhost")) {
    var sitelink = "https://localhost/guusbaan/public";
} else if (window.location.origin.includes("127.0.0.1")) {
    var sitelink = "https://127.0.0.1:8000";
} else {
    var sitelink = window.location.origin;
}
$(document).ready(function () {
    $('#kt_wrapper').addClass('loaded');
    setTimeout(function () {
        $('#kt_wrapper').addClass('loaded-done');
    }, 300);

    $('a').on('click', function (event) {
        if ($(this).attr('target') === "_blank"|| $(this).hasClass('link-default')) {
            // be default
        } else {

            event.preventDefault();
            if ($(this).attr('class') == null && $(this).attr('href').length > 2) {
                $('#kt_wrapper').removeClass('loaded-done');
                setTimeout(function (url) {

                    $('#kt_wrapper').removeClass('loaded');
                }, 200);
            }

            if ($(this).attr('href').length > 2) {
                $('#kt_wrapper').removeClass('loaded-done');
                window.togourl = $(this).attr('href');
                setTimeout(function (url) {

                    $('#kt_wrapper').removeClass('loaded');
                }, 200);
                setTimeout(function (url) {

                    location.href = window.togourl;
                }, 50);

                setTimeout(function () {
                    $('#kt_wrapper').addClass('loaded');
                }, 1000);
                setTimeout(function () {
                    $('#kt_wrapper').addClass('loaded-done');
                }, 1200);
            }
        }

    });

    $("#event_type_image").change(function () {
        var el = '#event_type_image_preview';
        readURL(this, el);
    });

    if($('.feelingrow').length) {
        console.info('Start handleFeelingSelector.')
        handleFeelingSelector();
    }

    if($('input[name=date_from]').length && $('input[name=date_to]').length) {
        handleDateCheck();
    }
});

function dateCheck() {
    const from = $('input[name=date_from]').val();
    const to = $('input[name=date_to]').val();

    let correct = false;
    if(from !== "" && to !== "") {
        correct = (new Date(from)) < (new Date(to));
    }

    if (correct) {
        $('button[type=submit]').attr('disabled', false);
        $('button[type=submit]').removeClass('disabled');
        $('.dateNotification').hide();
    } else {
        $('button[type=submit]').attr('disabled', true);
        $('button[type=submit]').addClass('disabled');
        $('.dateNotification').show();
    }
}

function handleDateCheck() {
    $('button[type=submit]').attr('disabled', true);
    $('button[type=submit]').addClass('disabled');

    dateCheck();

    $('input[name=date_from], input[name=date_to]').on('blur', dateCheck);
}

function handleFeelingSelector() {
    const parent = $('.feelingrow');

    parent.find(".smiley").on('click', function() {
        // Find label with the correct "for" attr.
        const destinationId = $(this).closest('label').attr('for');

        // Unset all other inputs, then set the selected one.
        parent.find('input[type=radio]').prop('checked', 'false');
        parent.find('input[type=radio]#' + destinationId).prop('checked', 'true');

        // Remove all active classes, then set the correct one.
        parent.find('label').removeClass('active');
        parent.find('label[for='+destinationId+']').addClass('active');
    })
}

require('../plugins/custom/fullcalendar/fullcalendar');
import nlLocale from '@fullcalendar/core/locales/nl'

var KTCalendarBasic = function () {

    return {
        //main function to initiate the module
        init: function () {
            var todayDate = moment().startOf('day');
            var YM = todayDate.format('YYYY-MM');
            var YESTERDAY = todayDate.clone().subtract(1, 'day').format('YYYY-MM-DD');
            var TODAY = todayDate.format('YYYY-MM-DD');
            var TOMORROW = todayDate.clone().add(1, 'day').format('YYYY-MM-DD');

            var calendarEl = document.getElementById('kt_calendar');
            var calendar = new FullCalendar.Calendar(calendarEl, {
                locale: nlLocale,
                plugins: ['bootstrap', 'interaction', 'dayGrid', 'timeGrid', 'list'],
                themeSystem: 'bootstrap',

                isRTL: KTUtil.isRTL(),

                header: {
                    left: 'prev,next today',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay'
                },

                height: 800,
                contentHeight: 780,
                aspectRatio: 3,  // see: https://fullcalendar.io/docs/aspectRatio

                nowIndicator: true,
                now: TODAY,

                views: {
                    dayGridMonth: {buttonText: 'maand'},
                    timeGridWeek: {buttonText: 'week'},
                    timeGridDay: {buttonText: 'dag'}
                },

                defaultView: 'dayGridMonth',
                defaultDate: TODAY,

                editable: false,
                eventLimit: true, // allow "more" link when too many events
                navLinks: true,
                events: sitelink + '/ajax/events',

                eventRender: function (info) {
                    var element = $(info.el);

                    if (info.event.extendedProps && info.event.extendedProps.description) {
                        if (element.hasClass('fc-day-grid-event')) {
                            element.data('content', info.event.extendedProps.description);
                            element.data('placement', 'top');
                            KTApp.initPopover(element);
                        } else if (element.hasClass('fc-time-grid-event')) {
                            element.find('.fc-title').append('<div class="fc-description">' + info.event.extendedProps.description + '</div>');
                        } else if (element.find('.fc-list-item-title').lenght !== 0) {
                            element.find('.fc-list-item-title').append('<div class="fc-description">' + info.event.extendedProps.description + '</div>');
                        }
                    }
                }
            });

            calendar.render();
        }
    };
}();

jQuery(document).ready(function () {
    if ($('#kt_calendar').length > 0) {
        KTCalendarBasic.init();
    }
});


function readURL(input, el) {
    if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = function (e) {
            $(el).attr('src', e.target.result);
            $(el).fadeIn();
        }
        reader.readAsDataURL(input.files[0]); // convert to base64 string
    }
}

function readURL2(input, el) {
    if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = function (e) {
            $(el).css('background-image', "url('" + e.target.result + "')");
        }
        reader.readAsDataURL(input.files[0]); // convert to base64 string
    }
}
