require("jquery.repeater");
require("bootstrap-switch");

function goal_form() {
    $('#goal_form #kt_repeater_2').repeater({
        initEmpty: false,

        defaultValues: {
            'text-input': 'foo'
        },

        show: function() {
            $(this).slideDown();
        },

        hide: function(deleteElement) {
            if(confirm('Are you sure you want to delete this element?')) {
                $(this).slideUp(deleteElement);
            }
        }
    });

    $('#goal_form').on('submit', function (e) {
        let values = $('#goal_form #kt_repeater_2').repeaterVal();
        values = JSON.stringify(values[""]);

        $("#goal_form [name=goals]").val(values);

        return true;
    })
}
function goal_form_edit() {
    $('[data-switch=true]').bootstrapSwitch();
    $('#goal_form_edit #kt_repeater_3').repeater({
        initEmpty: false,

        defaultValues: {
            'text-input': 'foo'
        },

        show: function() {
            $(this).slideDown();
        },

        hide: function(deleteElement) {
            if(confirm('Are you sure you want to delete this element?')) {
                $(this).slideUp(deleteElement);
            }
        }
    });


}

$(document).ready(function () {

    if($('#goal_form').length || $('#goal_form_edit').length) {
        goal_form();

        $('#add_drijfveer').on('click', function(){
            var html = '\n' +
                '                                            <div class="mb-2">\n' +
                '                                                <input class="form-control form-control-lg form-control-solid"\n' +
                '                                                       name="drijfveer[]" type="text" value=""/>\n' +
                '                                            </div>';
            $('#term-drijfveer-list').append(html);
        });
    }

    if($('#goal_form').length || $('#goal_form_edit').length) {
        goal_form_edit();

        $('#add_goal').on('click', function(){
            var html = '\n' +
                '                                                <div class="mb-2">\n' +
                '                                                    <input class="form-control form-control-lg form-control-solid"\n' +
                '                                                           name="terms[]" type="text" value=""/>\n' +
                '                                                </div>';
            $('#term-goal-list').append(html);
        });
    }
});
