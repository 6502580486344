function handleCycle(e, carousel) {
    const main = $(carousel);
    const photos = main.find(".photo");
    const numPhotos = photos.length;

    const activePhoto = photos.filter((p) => photos[p].classList.contains('current'));
    const activeIndex = $(activePhoto).data('index');
    let counter = activeIndex - 1;
    const loop = setInterval(function () {

        const currentActive = photos.filter((p) => photos[p].getAttribute("data-index") == (counter % numPhotos) + 1)[0];
        const nextActive = photos.filter((p) => photos[p].getAttribute("data-index") == ((counter + 1) % numPhotos) + 1)[0];
        const nextNext = photos.filter((p) => photos[p].getAttribute("data-index") == ((counter + 2) % numPhotos) + 1)[0];

        const nnc = $(nextNext).find('.content');
        if (nnc.css("background-image") == "none") {
            nnc.css("background-image", "url('" + $(nextNext).data('src') + "')");
        }
        nextNext.classList.add('next')

        nextActive.classList.add('current');
        nextActive.classList.remove('next');

        currentActive.classList.remove('current')

        counter++;
    }, 250);

    main.on('mouseout', function () {
        clearInterval(loop);
    })
}

$(document).ready(function () {
    const imageUploaders = $("#kt_image_2, .kt_image_2");

    if (imageUploaders.length) {
        for (let i = 0; i < imageUploaders.length; i++) {
            const uploader = imageUploaders[i];

            $(uploader).find('input[type=file]')
                .on('change', function (e) {
                    const [file] = e.target.files;
                    if (file) {
                        $(uploader).find('.image-input-wrapper')
                            .css("background-image", 'url("' + URL.createObjectURL(file) + '")')
                    }
                })
        }
    }


    const photoCarousels = $('.photoCarousel');

    for (let i = 0; i < photoCarousels.length; i++) {
        const carousel = photoCarousels[i];

        const content = $(carousel).find('.content');

        const first = $(content).find(".photo[data-index=1]");
        first.addClass("current");
        first.find('.content').css("background-image", "url('" + first.data('src') + "')");

        const second = $(content).find(".photo[data-index=2]").addClass("next");
        second.addClass("next");
        second.find('.content').css("background-image", "url('" + second.data('src') + "')");

        $(carousel).on('mouseover', (e) => handleCycle(e, carousel));
    }

    $('.orientationbuttons button').on('click', function () {
        const targetIndex = $(this).data('index');

        const photoCarousels = $('.photoCarousel');

        for (let i = 0; i < photoCarousels.length; i++) {
            const main = $(photoCarousels[i]);
            const photos = main.find(".photo");
            const numPhotos = photos.length;

            const activePhoto = photos.filter((p) => photos[p].classList.contains('current'));
            const activeIndex = $(activePhoto).data('index');
            let counter = activeIndex - 1;

            const currentActive = photos.filter((p) => photos[p].getAttribute("data-index") == (counter % numPhotos) + 1)[0];
            const nextActive = photos.filter((p) => photos[p].getAttribute("data-index") == ((counter + 1) % numPhotos) + 1)[0];

            const targetActive = photos.filter((p) => photos[p].getAttribute("data-orientationIndex") == targetIndex)[0];
            const targetNext = photos.filter((p) => photos[p].getAttribute("data-orientationIndex") == ((targetIndex + 1) % numPhotos))[0];

            if (targetActive) {
                currentActive.classList.remove('current');
                nextActive.classList.remove('next');

                targetActive.classList.add('current');
                if(targetNext) {
                    targetNext.classList.add('next');
                }

                const nnc = $(targetActive).find('.content');
                if (nnc.css("background-image") == "none") {
                    nnc.css("background-image", "url('" + $(targetActive).data('src') + "')");
                }

                const nnct = $(targetNext).find('.content');
                if (nnc.css("background-image") == "none") {
                    nnc.css("background-image", "url('" + $(targetNext).data('src') + "')");
                }
            }

        }
    })
})
